import {Links} from '../routes/PathLink'

export const user_links = [
];

export const dealer_links = [
    Links.AMC_BID,
    Links.MY_PROPOSALS,
];

export const customer_reject_links = [
    Links.AMC_BID,
    Links.MY_PROPOSALS,
    Links.ADD_PRODUCT,
    Links.MY_PRODUCTS,
    Links.AMS_TICKETS,
    Links.CMS_TICKETS,
    Links.MY_PRODUCTS,
];